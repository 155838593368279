import React from 'react';
import PageTemplate from '../templates/pageTemplate';

const PrivacyPage = () => (
  <PageTemplate
    title="Privacy Policy" 
    description="Here is the privacy policy for Keto Perosnal Plan."
  >
    <Privacy/>
  </PageTemplate>
)

const Privacy = () => (
  <div>
    <h1 class="text-center">Privacy Policy</h1>

    <p>
      This privacy policy sets out how Keto Perosnal Plan uses and protects any information that you give when you use
      the website.
    </p>

    <p>
      Keto Perosnal Plan is committed to ensuring that your privacy is protected. Should we ask you to provide certain
      information by which you can be identified when using this website, then you can be assured that it will only be
      used in accordance with this privacy statement.
    </p>

    <p>
      Keto Perosnal Plan may change this policy from time to time by updating this page. You should check this page from
      time to time to ensure that you are happy with any changes.
    </p>

    <h2>What we collect</h2>

    <p>
      We may collect the following information:
    </p>

    <ul>
      <li>Name</li>
      <li>Gender</li>
      <li>Contact information including email address</li>
      <li>Any personal information relevant for dietary purposes (at your discretion)</li>
      <li>Demographic information such as postcode, preferences and interests</li>
      <li>Other information relevant to customer surveys and/or offers</li>
    </ul>

    <h2>What we do with the information we gather</h2>

    <p>
      We require this information to understand your needs and provide you with a better service, and in particular
      for the following reasons:
    </p>

    <ul>
      <li>Internal record keeping.</li>
      <li>We may use the information to improve our products and services.</li>
      <li>
          We may periodically send promotional emails about new products, special offers or other information which we
          think you may find interesting using the email address which you have provided.
      </li>
      <li>
          From time to time, we may also use your information to contact you for market research purposes. We may
          contact you by email. We may use the information to customise the website according to your interests.
      </li>
    </ul>

    <h2>Security</h2>

    <p>
      We are committed to ensuring that your information is secure. In order to prevent unauthorised access or
      disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure
      the information we collect online.
    </p>
    
    <h2>How we use cookies</h2>
        
    <p>
      A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the
      file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. We use
      cookies for member access to this website.
    </p>
    <p>
      We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page
      traffic and improve our website in order to tailor it to customer needs. We only use this information for
      statistical analysis purposes and then the data is removed from the system. Overall, cookies help us provide you
      with a better website, by enabling us to monitor which pages you find useful and which you do not.
    </p>
    <p>
      A cookie in no way gives us access to your computer or any information about you, other than the data you choose
      to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies,
      but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from
      taking full advantage of the website or potentially result in the loss of function in some areas, member access
      in particular. For this reason, we recommend that you continue allowing the use of cookies.
    </p>
    
    <h2>Links to other websites</h2>

    <p>
      Our website may contain links to other websites of interest. However, once you have used these links to leave our
      site, you should note that we do not have any control over that other website. Therefore, we cannot be
      responsible for the protection and privacy of any information which you provide whilst visiting such sites and
      such sites are not governed by this privacy statement. You should exercise caution and look at the privacy
      statement applicable to the website in question.
    </p>
    
    <h2>Your personal information</h2>

    <p>
      We will not sell, distribute or lease your personal information to third parties unless we have your permission
      or are required by law to do so. We may use your personal information to send you promotional information about
      third parties which we think you may find interesting.
    </p>
  </div>
)

export default PrivacyPage;